import { createRoot } from 'react-dom/client'
import './styles.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { App } from './App'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SlidingText from './slidingtext'
import Bottomslider from './bottomslider'
import About from './pages/about'
import { Icon } from '@iconify/react'
import linkedinIcon from '@iconify/icons-logos/linkedin-icon'
import githubIcon from '@iconify/icons-logos/github'
import twitterIcon from '@iconify/icons-logos/twitter'
import React from 'react'
import { Container } from '@mui/material'
import Stack from '@mui/material/Stack';
import { Analytics } from '@vercel/analytics/react'

/*
Command: npx gltfjsx@6.2.3 model.glb --transform --simplify --resolution=2048
*/

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const isNotMobile = (window.screen.width > 700);
const portfolioWidth = ( isNotMobile ?  (640) : (290));
const portfolioHeight = ( isNotMobile ? (460) : (200));
const iconStyle = {
  fontSize: "400%",
  margin: "9% 5% 0 5%",
  padding: 5,
  align: "center"
};


function Root() {
  return (
    <>
      <SlidingText/>
      <div className="appRender" style={{width:"100vw", height: "50vh"}}>
        <Analytics />
        <App />
      </div>
      <div style={{ position: 'absolute', pointerEvents: 'none', top: 0, left: 0, width: '100vw', height: '100vh' }}>
        <a href="https://hernantech.github.io" style={{ position: 'absolute', bottom: 40, left: 30, fontSize: '13px' }}>
          <br />
        </a>
      </div>{' '}
      <Bottomslider/>
      <div className={"testgrid"}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid item p={isNotMobile ? (15) : (1)} alignItems="center">
            <Item>
              {<iframe
                src="https://indd.adobe.com/embed/c4eb285c-7b4f-49ee-bc38-5926bed0d74d?startpage=1&allowFullscreen=true"
                width={portfolioWidth} height={portfolioHeight} frameBorder="0" allowFullScreen="True"></iframe>}
            </Item>
          </Grid>
        </Box>

      </div>
      <div align={"center"}>
        <Stack
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          gap={5}
          paddingX= "10vh"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}

        >
          <Container>
          <a href="https://www.linkedin.com/in/hernan-tech/">
            <Icon
              icon={linkedinIcon}
              style={iconStyle}/>
          </a>
          </Container>
          <Container>
            <a href="https://github.com/hernantech">
            <Icon
              icon={githubIcon}
              style={iconStyle}
            />
            </a>
          </Container>
          <Container>
          <a href="https://x.com/cokeandmath">
          <Icon
            icon={twitterIcon}
            style={iconStyle}
          />
          </a>
          </Container>
        </Stack>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingX="10vh"
        >
          <About
            resumeBasicInfo = {{description_header: "Hi", description: "Hi—I’m Alejandro Hernandez. Extroverted, enthusiastic, and friendly, I study both Pure Mathematics and Computer Science (first love still is physics!) with nearly a decade of combined work experience in software development and IT. Bilingual (Spanish), confident and experienced in public speaking. My personal philosophy seeks compassionate/constructive solutions to critical issues with clients. Detail-oriented writer. Autodidact concurrently enthusiastic toward learning new skills from others. When not working, can often be found either surfing and cycling at the beach!", section_name: {about: "About me"}}}
            sharedBasicInfo={ {image : "null"}}
          />
        </Box>
      </div>
      <br/>
    </>
  )
}

createRoot(document.getElementById('root')).render(
  <BrowserRouter><Root />

  </BrowserRouter>
)


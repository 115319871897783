import React, { Component } from "react";
import { Icon } from "@iconify/react";
import linkedinIcon from "@iconify/icons-logos/linkedin-icon";
import githubIcon from "@iconify/icons-logos/github";
import twitterIcon from "@iconify/icons-logos/twitter";
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'




class About extends Component {
  render() {
    if (this.props.sharedBasicInfo) {
      var profilepic = "images/" + this.props.sharedBasicInfo.image;
    }
    if (this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.about;
      var about = this.props.resumeBasicInfo.description;
    }

    return (
      <section id="about">
             <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >

                    <br />
                    {about}
                  </div>
                </div>
      </section>
    );
  }
}

export default About;